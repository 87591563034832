/* ==========================================================================
  Fiche dtail
  ========================================================================== */

.fiche-detail_label {
  position: absolute;
  z-index: 10;
  top: 1rem;
  right: 1rem;
}

.col-sm-detail {
  width: 20%;
  padding-bottom: 20%;
  margin: 0 .5rem;
  position: relative;

  img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-bottom: 1rem;
  }
}

.detail-marque--img {
  display: inline-block;
  max-width: 150px;
  max-height: 50px;
}

.detail__add-info {
  padding: .7rem 0;
  border-bottom: solid 1px $gray-lighter;

  img {
    display: block;
    height: 1.5rem;
  }
}

.slider-proposition.slick-dotted {

  @media(min-width: $screen-lg-min) {
    width: 95%;
    margin: auto;
    margin-bottom: 5rem;
  }
}

.table tr {
  border-top: solid 2px #eee;
}

.fiche-produit {
  border: solid 1px #e5e5e5;
  background-color: white;
  margin-bottom: 1.5rem;
  border-radius: $border-radius-base;
  overflow: hidden;
  box-shadow: 0 1rem 2rem -.5rem rgba(black, .1);

  .col {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media(min-width: $screen-md-min) {
    display: flex;

    .col {
      border-left: solid 1px #e5e5e5;

      &:first-child {
        border: none;
      }
    }
  }
}