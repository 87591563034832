/* ==========================================================================
   Accueil
   ========================================================================== */

// .main-accueil {
//   min-height: 50vh;
// }



/* ==========================================================================
Parallax
========================================================================== */

.parallax-window {
align-items: center;
min-height: 30vh;
overflow: hidden;
}


.parallax-window-accueil {
@extend .parallax-window;
display: flex;
flex-flow: row wrap;
justify-content: center;
text-align: center;
min-height: 90vh;
background: linear-gradient(to bottom, rgba(black, 0) 0%, rgba(black, .6) 100%);

>a {
   display: inline-block;
   position: relative;
   font-size: 4rem;
   text-decoration: none;
   text-transform: uppercase;
   font-weight: 700;
   color: white;

   >img {
      @extend .img-responsive;
      max-width: 90%;
      margin: 0 auto;
      transition: transform ease-out .3s;
   }

   >span {
      display: inline-block;
      transition: all ease-out .3s;
      text-shadow: .2rem .25rem .5rem rgba(black, .6);
   }

   @media(min-width: $screen-sm-min) {
      font-size: 4.5rem;
      
      &:hover {
         opacity: 1;

      >img {
         transform: scale(1.1);
      }

      >span {
         letter-spacing: .5rem;
         text-shadow: .4rem .5rem 1rem rgba(black, .2);
      }
      }
   }

   @media(min-width: $screen-md-min) {
      font-size: 5.5rem;
   }
}
}